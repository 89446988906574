import { BaseComponent, Prop, Component } from '../';

import type { IInnerHTMLLib } from './index.d';

/**
 * Renders the received content as HTML
 *
 * Consideration: only use with trusted HTML as the entire mechanism is inherently unsafe for XSS attacks.
 * Works in both client and server rendering.
 */
@Component({})
export class InnerHTMLLib extends BaseComponent<IInnerHTMLLib, unknown> {
  @Prop({ type: [Array, String, Number], required: true })
  contents: string | number | Array<string | number>;

  get normalizedContents() {
    return this.contents ? (Array.isArray(this.contents) ? this.contents.join('') : this.contents.toString()) : '';
  }

  render() {
    return this.$createElement(
      'div',
      {
        domProps: {
          innerHTML: this.normalizedContents,
        },
      },
      [],
    );
  }
}
